<template>
  <a-row class="product mt-5 mb-3" :style="{ overflowY: products.length >=5 && 'scroll'}">
    <div v-for="(item, index) in products" :key="index">
      <a-row class="product-item d-flex align-items-center">
        <a-col :span="1">
          <a-checkbox :checked="item.item_checked" @change="(value) => handleItemCheckbox(value, item.id)" />
        </a-col>

        <a-col :span="4" class="d-flex justify-content-center align-items-center">
          <div class="image d-flex justify-content-center align-items-center">
            <img height="35" width="35" :src="item.img_url ? item.img_url : 'https://ik.imagekit.io/powerbiz/no-image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1603265244531'" alt="img" />
          </div>
        </a-col>

        <a-col :span="5" class="">
          <div class="pbz-font button-sm-medium product-item__title">
            {{ item.catalog_title }}
          </div>
          <div class="pbz-font caption-medium product-item__sku">
            SKU: {{ item.sku }}
          </div>
        </a-col>

        <a-col :span="4" class="d-flex justify-content-center align-items-center flex-column">
          <div class="product-item__sku">
            Qty
          </div>
          <div> {{ item.quantity }} </div>
        </a-col>

        <a-col :span="6" class="d-flex justify-content-center align-items-center flex-column">
          <div class="product-item__sku">
            Inventori Booking
          </div>
          <div class="product-item__title">
            {{ wordingBookingState(item.booking_state) }}
          </div>
        </a-col>
      </a-row>
    </div>
  </a-row>
</template>

<script>
import {
  setWordingBookingState,
} from '@/utils/fulfillment/index'

export default {
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleItemCheckbox (event, id) {
      this.$emit('handleItemCheckbox', { event, id })
    },
    wordingBookingState (value) {
      return setWordingBookingState(value)
    },
    styleOrderUpdated (status) {
      const data = status.toLowerCase()

      const style = {
        boxShadow: '0px 5px 5.3625px rgba(0, 0, 0, 0.055)',
        borderRadius: '33px',
        color: '#FFFFFF',
        padding: '4px 12px',
      }

      switch (data) {
        case 'pengiriman terpisah': {
          return {
            ...style,
            background: '#454655',
          }
        }

        case 'batal': {
          return {
            ...style,
            background: '#E00000',
          }
        }

        default: {
          return {}
        }
      }
    },
  },
}
</script>

<style>

</style>
