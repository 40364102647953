<template>
  <div>
    <a-row class="mt-5 mb-3 d-flex align-items-center">
      <a-col :span="8" class="d-flex justify-content-start align-items-center">
        <div
          style="
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #1A1A1A;
          "
          class="d-flex justify-content-center align-items-center"
        >
          {{ $t('fulfillment.itemProduct') }}
        </div>
      </a-col>
    </a-row>

    <a-row class="product mt-5 mb-3" :style="{ overflowY: productsToUpdate.length >=5 && 'scroll'}">
      <div v-for="(item, index) in productsToUpdate" :key="index">
        <a-row class="product-item d-flex align-items-center">
          <a-col :span="1">
            <a-checkbox :checked="item.item_checked" @change="(value) => handleItemCheckbox(value, item.id)" />
          </a-col>

          <a-col :span="4" class="d-flex justify-content-center align-items-center">
            <div class="image d-flex justify-content-center align-items-center">
              <img height="35" width="35" :src="item.img_url ? item.img_url : 'https://ik.imagekit.io/powerbiz/no-image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1603265244531'" alt="img" />
            </div>
          </a-col>

          <a-col :span="5" class="">
            <div class="pbz-font button-sm-medium product-item__title">
              {{ item.catalog_title }}
            </div>
            <div class="pbz-font caption-medium product-item__sku">
              SKU: {{ item.sku }}
            </div>
          </a-col>

          <a-col :span="4" class="d-flex justify-content-center align-items-center flex-column">
            <div class="product-item__sku">
              Qty
            </div>
            <div> {{ item.quantity }} </div>
          </a-col>

          <a-col :span="6" class="d-flex justify-content-center align-items-center flex-column">
            <div class="product-item__sku">
              Inventori Booking
            </div>
            <div class="product-item__title">
              {{ wordingBookingState(item.booking_state) }}
            </div>
          </a-col>

          <a-col :span="4" class="d-flex justify-content-center align-items-center flex-column">
            <div :style="styleOrderUpdated(item.status_updated)" class="product-item__title">
              {{ item.status_updated }}
            </div>
          </a-col>
        </a-row>
      </div>
    </a-row>

    <a-row class="footer-update-order mt-5">
      <a-col :span="12" class="d-flex justify-content-center align-items-center">
        <div class="cancel pbz-font button-sm-medium" @click.prevent="() => $emit('handleCancel')">
          Batal
        </div>
      </a-col>
      <a-col :span="12" class="d-flex justify-content-center align-items-center">
        <div
          class="pbz-font button-sm-medium d-flex justify-content-center align-items-center"
          :class="{
            ['save']: disabledButtonFooter ? false : true,
            ['disabled-save']: disabledButtonFooter ? true : false,
          }"
          @click.prevent="handleSubmitButtonFooter"
        >
          Selanjutnya
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {
  setWordingBookingState,
} from '@/utils/fulfillment/index'

export default {
  props: {
    propsProducts: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      showListItem: true,
      showListItemToUpdate: false,
      disabledButtonFooter: true,
      productsToUpdate: [],
    }
  },
  watch: {
    propsProducts: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.productsToUpdate = Array.isArray(value) && value.length > 0 ? value.map(item => ({
          ...item,
          status_updated: '',
        })) : []
      },
    },
    productsToUpdate: {
      immediate: true,
      deep: true,
      handler: function (value) {
        let itemChecked = 0

        value.forEach((item) => {
          if (item.item_checked) {
            itemChecked++
          }
        })

        // eslint-disable-next-line
        this.disabledButtonFooter = itemChecked > 0 ? false : true
      },
    },
  },
  methods: {
    handleItemCheckbox (event, id) {
      const key = event.target.checked

      this.productsToUpdate = this.productsToUpdate.map((item) => ({
        ...item,
        item_checked: item.id === id ? key : item.item_checked,
        status_updated: item.id === id && key ? 'Batal' : item.id === id && !key ? '' : item.status_updated,
      }))
    },
    wordingBookingState (value) {
      return setWordingBookingState(value)
    },
    styleOrderUpdated (status) {
      const data = status.toLowerCase()

      const style = {
        boxShadow: '0px 5px 5.3625px rgba(0, 0, 0, 0.055)',
        borderRadius: '33px',
        color: '#FFFFFF',
        padding: '4px 12px',
      }

      switch (data) {
        case 'pengiriman terpisah': {
          return {
            ...style,
            background: '#454655',
          }
        }

        case 'batal': {
          return {
            ...style,
            background: '#E00000',
          }
        }

        default: {
          return {}
        }
      }
    },
    handleSubmitButtonFooter () {
      if (this.disabledButtonFooter) {
        // eslint-disable-next-line
        return
      }
      // console.log(this.productsToUpdate)

      const payload = {
        line_items: this.productsToUpdate.filter(item => item.item_checked),
      }

      this.$emit('setPayloadCancelItem', payload)
      this.$emit('handleModalConfirmation', true)
    },
  },
}
</script>

<style>

</style>
